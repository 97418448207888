import React, { FC, memo, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { WorkDay } from "@interfaces/businessGoals.interface";
import { IEventsOfDay } from "@interfaces/eventsOfDay.interface";
import { IMeet, IMeetParticipants, IMeetRepeat, TTypeMeetingStatus, TTypeParticipants } from "@interfaces/meet.interface";
import {
  useChangeAuthorMutation,
  useDeleteMeetingSerieMutation,
  useDeleteSingleOrExceptionMeetingMutation,
  useUpdateMeetingStatusMutation,
  useUpdateSingleOrSerieMeetingMutation,
} from "@services/meetApi";
import { PlanningWork } from "@shared";
import { AttachFileContextMenuButton } from "@shared/fileAttachments";
import { RootState } from "@store";
import { selectCalendar } from "@store/calendar";
import { selectCurrentDate, setJoinData } from "@store/screenDay";
import { selectIsDogmaUser, userSelector } from "@store/user";
import { TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import globalState from "@utils/globalState";
import { showToast } from "@utils/toast";
import { toFormatDate, toFormatHHmmss, toFormatISO } from "@utils/toFormatTime";

import { ToManage } from "../components";
import { ModalDelete } from "../modalDelete";
import { tabs } from "../modalDelete/constants";

import { TKeyButtons, config } from "./config";

interface IActions {
  meet: IMeet;
  setIsSave: (arg: boolean) => void;
  setIsVisibleEdit: (arg: boolean) => void;
  close: () => void;
  closeOverModal: () => void;
  repeat: IMeetRepeat | null;
  openFilePicker: ({ closeOverModal }: { closeOverModal?: () => void }) => void;
  componentMeetingTasks: JSX.Element;
  isEditAllowed: boolean;
  isEditFiredAuthor: boolean;
}

export const Menu: FC<IActions> = memo(function Menu({
  meet,
  setIsSave,
  close,
  setIsVisibleEdit,
  repeat,
  closeOverModal,
  componentMeetingTasks,
  isEditAllowed,
  openFilePicker,
  isEditFiredAuthor,
}) {
  const [deleteSingleOrExceptionMeeting] = useDeleteSingleOrExceptionMeetingMutation();
  const [deleteMeetingSerie] = useDeleteMeetingSerieMutation();
  const [updateSingleMeetOrSerie] = useUpdateSingleOrSerieMeetingMutation();
  const [updateMeetingStatus] = useUpdateMeetingStatusMutation();
  const [changeAuthor] = useChangeAuthorMutation();

  const dispatch = useDispatch();
  const { currentUser } = userSelector();
  const { periodToLoadCalendar } = useSelector(selectCalendar);

  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleDeleteRepeat, setIsVisibleDeleteRepeat] = useState(false);
  const [isVisibleDelete, setIsVisibleDelete] = useState(false);
  const [isVisibleCancel, setIsVisibleCancel] = useState(false);
  const [isVisibleManage, setIsVisibleManage] = useState(false);

  const { startDate } = useSelector((state: RootState) => state.screenDay);
  const date = useSelector(selectCurrentDate);
  const isDogmaUser = useSelector(selectIsDogmaUser);

  const isGroupAll = meet?.presetGroups?.length && meet?.presetGroups?.[0]?.type === "ALL_IN_SPACE";

  const isTransferMeetManagementAvailable = useMemo(
    () => meet.createdByCurrentUser && dayjs(meet.startTime).isAfter(dayjs(), "minute") && Number(meet.participants?.length) > 1,
    [meet],
  );

  useEffect(() => {
    document.getElementById("modalMeet").addEventListener("click", (e) => {
      if (!document.getElementById("meetActions")?.contains(e?.target)) {
        close();
      }
    });
  }, []);

  const handlePressDelete = () => {
    if (repeat) {
      setIsVisibleDeleteRepeat(true);
    } else {
      setIsVisibleDelete(true);
    }
  };

  const handlePressEdit = () => {
    if (repeat) {
      setIsVisibleEdit(true);
    } else {
      setIsSave(false);
    }
  };

  const handlePressCancel = () => {
    if (repeat) {
      setIsVisibleCancel(true);
    } else {
      handleCancel(true);
    }
  };

  const handleToManage = (userId: string) => {
    changeAuthor({ id: meet.id, userId });
    setIsVisibleManage(false);
    close();
  };

  const handleMove = async (days: WorkDay[]) => {
    const day = days[0];
    const startTime = toFormatISO(dayjs(`${day.date} ${toFormatHHmmss(dayjs(day.startTime))}`));
    const endTime = toFormatISO(dayjs(`${day.date} ${toFormatHHmmss(dayjs(day.endTime))}`));

    updateSingleMeetOrSerie({
      id: meet.id,
      repeat: !!meet.repeat,
      date: toFormatDate(date),
      data: { startTime, endTime },
    });

    closeOverModal();
  };

  const handleDelete = (isSerie?: boolean) => {
    if (!repeat || (meet?.parentEvent && !isSerie)) {
      deleteSingleOrExceptionMeeting({
        id: meet.id,
        date: toFormatDate(date),
        isException: false,
        calendarPeriodStart: periodToLoadCalendar?.startDate,
        calendarPeriodEnd: periodToLoadCalendar?.endDate,
      });
    } else {
      if (isSerie) {
        deleteMeetingSerie({
          id: meet?.parentEvent?.id ?? meet.id,
          date: toFormatDate(date),
          calendarPeriodStart: periodToLoadCalendar?.startDate,
          calendarPeriodEnd: periodToLoadCalendar?.endDate,
        });
      } else {
        deleteSingleOrExceptionMeeting({
          id: meet.id,
          date: toFormatDate(date),
          isException: true,
          calendarPeriodStart: periodToLoadCalendar?.startDate,
          calendarPeriodEnd: periodToLoadCalendar?.endDate,
        });
      }
    }

    closeOverModal();
  };

  const handleCancel = (isSerie?: boolean) => {
    updateSingleMeetOrSerie({
      id: isSerie ? meet?.parentEvent?.id ?? meet.id : meet.id,
      date: toFormatDate(date),
      repeat: !!meet.repeat,
      parentEvent: meet.parentEvent as unknown as IMeet,
      changeSerie: isSerie,
      data: { active: false },
      isRemoveFromList: true,
    });
    close();
  };

  const handleUpdateStatus = (type: TTypeMeetingStatus) => {
    updateMeetingStatus({ id: meet.id, date: toFormatDate(date), type });
  };

  const handleNewThisParticipants = () => {
    closeOverModal();

    let reorderList = false;

    const initialParticipantsWithInviteRights =
      meet?.participants?.map((item) => {
        let fixedStatus: TTypeParticipants | null = item.status === "OWNER" ? item.status : null;

        if (!meet?.createdByCurrentUser && currentUser?.id) {
          fixedStatus = item.userId === currentUser.id ? "OWNER" : null;
          reorderList = true;
        }

        return {
          ...item,
          permissions: ["ADD_PARTICIPANTS"],
          status: fixedStatus,
          statusComment: null,
        } as unknown as IMeetParticipants;
      }) ?? [];

    let participantsList = initialParticipantsWithInviteRights;

    if (reorderList) {
      participantsList = [];
      initialParticipantsWithInviteRights.forEach((item) =>
        item.status === "OWNER" ? participantsList.unshift(item) : participantsList.push(item),
      );
    }
    const usersWithOutFired = [...participantsList].filter((item) => item.user.active);
    const usersWithOutDeleted = [...meet.externalUsers].filter((user) => !user.deleted);

    setTimeout(() => dispatch(setJoinData({ participants: usersWithOutFired, externalUsers: usersWithOutDeleted })), 500);
  };

  const handleCopyParticipantsFIO = () => {
    const dogmaUsersFIO = meet?.participants?.reduce((acc, current) => {
      const user = current.user;
      const firstName = user?.firstName ? ` ${user.firstName}` : "";
      const lastName = user?.lastName ? user.lastName : "";
      const middleName = user?.middleName ? ` ${user.middleName}` : "";

      return (acc += `${lastName}${firstName}${middleName}\n`);
    }, "");

    const exteranlUsersFIO = meet?.externalUsers?.reduce((acc, current) => {
      const lastName = current?.lastName ? current.lastName : "";
      const firstName = current?.firstName ? ` ${current.firstName}` : "";

      return (acc += `${lastName}${firstName}\n`);
    }, "");

    navigator.clipboard.writeText(`${dogmaUsersFIO}${exteranlUsersFIO}`).then(() => {
      showToast("copyParticipantsNamesDone");
    });
  };

  const handlers: Record<TKeyButtons, () => void> = {
    remove: () => handlePressDelete(),
    did_not_take: () => handleUpdateStatus("DID_NOT_TAKE_PLACE"),
    will_not_take: () => handleUpdateStatus("WILL_NOT_TAKE_PLACE"),
    cancel: () => handlePressCancel(),
    edit: () => handlePressEdit(),
    transfer: () => setIsVisible(true),
    newMeetThisParticipants: () => handleNewThisParticipants(),
    toBeOrganizer: () => setIsVisible(true),
    toManage: () => setIsVisibleManage(true),
    copyParticipantsFIO: () => handleCopyParticipantsFIO(),
  };

  const isRenderCancel = useMemo(
    () => dayjs().isBefore(dayjs(meet.startTime), "minute") && Number(meet.participants?.length) > 1,
    [meet.participants?.length, meet.startTime],
  );

  const renderItem = (key: TKeyButtons) =>
    (config[key].requiresAuthorRights && meet.createdByCurrentUser) || !config[key].requiresAuthorRights || isEditAllowed ? (
      <button style={styles.button} onClick={handlers[key]}>
        {config[key].icon}
        <TextFont size={16} style={{ marginLeft: 6, color: config[key].colorRed ? "red" : "#274335" }}>
          <FormattedMessage id={config[key].title} />
        </TextFont>
      </button>
    ) : (
      <></>
    );

  return (
    <div id="meetActions" style={{ ...styles.container, position: "absolute" }}>
      <RenderWithCondition condition={isEditAllowed}>{componentMeetingTasks}</RenderWithCondition>
      <RenderWithCondition condition={isEditAllowed}>
        <AttachFileContextMenuButton openFilePicker={() => openFilePicker({ closeOverModal: close })} />
      </RenderWithCondition>
      {renderItem("transfer")}
      {renderItem("edit")}
      <RenderWithCondition condition={!isGroupAll}>{renderItem("newMeetThisParticipants")}</RenderWithCondition>
      {isDogmaUser && renderItem(dayjs(meet.startTime).isAfter(dayjs(), "minute") ? "will_not_take" : "did_not_take")}
      <RenderWithCondition condition={isEditFiredAuthor}>{renderItem("toBeOrganizer")}</RenderWithCondition>
      <RenderWithCondition condition={isTransferMeetManagementAvailable}>{renderItem("toManage")}</RenderWithCondition>
      <RenderWithCondition condition={meet.participants.length > 1 || meet.externalUsers.length}>
        {renderItem("copyParticipantsFIO")}
      </RenderWithCondition>
      <RenderWithCondition condition={isRenderCancel}>{renderItem("cancel")}</RenderWithCondition>
      <RenderWithCondition condition={Number(meet.participants?.length) === 1 && meet.createdByCurrentUser}>
        {renderItem("remove")}
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible}>
        <PlanningWork
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          startDays={[{ ...meet, date: toFormatDate(dayjs(meet.startTime)) }]}
          onSave={handleMove}
          startDate={toFormatDate(startDate)}
          isMultiple={false}
          keyTitle="meetingTransferHeader"
          deadline={toFormatDate(dayjs(repeat?.endTime ?? ""))}
          timeEnabled
          disableAddTimeToggle
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleDelete}>
        <NotificationConfirm phraseId="meetingDeleteAlert" onOk={handleDelete} onCancel={() => setIsVisibleDelete(false)} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleCancel}>
        <ModalDelete
          close={() => setIsVisibleCancel(false)}
          deleteSerie={() => handleCancel(true)}
          deleteFromSerie={handleCancel}
          data={tabs}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleDeleteRepeat}>
        <ModalDelete
          close={() => setIsVisibleDeleteRepeat(false)}
          deleteSerie={() => handleDelete(true)}
          deleteFromSerie={handleDelete}
          data={tabs}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleManage}>
        <ToManage meet={meet} onClose={() => setIsVisibleManage(false)} onPress={handleToManage} />
      </ModalRenderWithCondition>
    </div>
  );
});

const styles = {
  container: {
    top: 100,
    right: 12,
    borderRadius: 8,
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.09)",
    backgroundColor: globalState.colorSchema.white,
    zIndex: 999,
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: "12px 5px 12px 8px",
    width: "100%",
    maxWidth: 262,
  },
};
