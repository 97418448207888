import React, { FC, useEffect, useRef, useState, useCallback, MouseEvent, useMemo } from "react";

import { isArray } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgAddPeople } from "@assets/people_add.svg";
import { IUnitedGroupItem } from "@components/screens/profile/components/groups/Groups";
import { NotificationConfirm } from "@components/ui/notification/NotificationConfirm";
import { RenderWithCondition, LoaderRenderWithCondition, ModalRenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces/index";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IGetUserParams, IStateUser } from "@interfaces/user.interface";
import { InviteExternalParticipantBottomSheet } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { AddNonExistingParticipantButton } from "@screens/day/components/timetable/main/modal/components/participants/components/AddNonExistingParticipantButton";
import { Groups, UserInformation } from "@screens/profile/components";
import { StoreTagTypes, api } from "@services/api";
import { IExternalUser } from "@services/meet.service";
import { useDeleteExternalParticipantMutation } from "@services/meetApi";
import { Colors } from "@theme/colors";
import { HeaderModal, TextFont, ModalUI, Loader, ArrowSvg, SearchInput, ItemListContentLoader } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";

import { useHandleParticipantSearch } from "../../../hooks/useHandleParticipantSearch";
import { useRoleModel } from "../../accessRights/useRoleModel";
import { isArraysOfObjectsDiffer } from "../../utils";

import { Item } from "./Item";
import { MeetsPastList } from "./MeetsPastList";
import RemoveParticipantsButton from "./RemoveParticipantsButton";

const eventHelperUtils = new EventHelperUtils();
const next = 16;

interface IProps {
  meet: IMeet;
  onSave: (user: (InterfaceUser.IStateUser | IExternalUser)[] | Partial<IUnitedGroupItem>[], isExternalUser?: boolean) => void;
  onClose: () => void;
  isSelectFromMeeting?: boolean;
  isSelectGroup?: boolean;
  isShowStatusBorder?: boolean;
  isMeet: boolean;
}

export const ParticipantsList: FC<IProps> = ({
  meet,
  onSave,
  onClose,
  isSelectFromMeeting = true,
  isSelectGroup = true,
  isShowStatusBorder = true,
  isMeet,
}) => {
  const isGroupAll = meet?.presetGroups?.length && meet?.presetGroups[0].type === "ALL_IN_SPACE";

  const {
    search,
    listData,
    isLoading,
    isFetching,
    selectedUsers,
    isInviteExternalParticipantBtnVisible,
    isInviteExternalParticipantModalVisible,
    searchedExternalUsersResponse,
    initialParticipants,
    setSearch,
    setCurrent,
    handleResetSearch,
    loadMoreData,
    setSearchedExternalUsers,
    setGetUsersParams,
    setIsInviteExternalParticipantModalVisible,
    setSelectedUsers,
    setCurrentSublistType,
  } = useHandleParticipantSearch({ meet, isGroupAll });
  const dispatch = useDispatch();

  const listInnerRef = useRef<HTMLDivElement>(null);

  const [isWriting, setIsWriting] = useState(false);
  const [selectUser, setSelectUser] = useState<InterfaceUser.IStateUser | undefined>(undefined);
  const [isListPastMeetsVisible, setIsListPastMeetsVisible] = useState(false);
  const [isGroupVisible, setIsGroupVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [pos, setPos] = useState({ left: 0, top: 0 });
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [externalUserIdToDelete, setExternalUserIdToDelete] = useState("");
  const [deletedExternalUsersIds, setDeletedExternalUsersIds] = useState<string[]>([]);

  const { isUserHasEditPermissions, isUserHasInvitePermissions } = useRoleModel(meet);
  const [isSaveBtnVisible, setIsSaveBtnVisible] = useState(false);
  const deletableUserIds = useMemo(() => new Set<string>(), []);

  const [deleteExternalParticipant] = useDeleteExternalParticipantMutation();

  const handleUserPress = useCallback(
    (data: IStateUser | IExternalUser | (IMeetParticipants | IExternalUser)[] | Partial<IUnitedGroupItem>[]) => {
      listInnerRef.current && setScrollPosition(listInnerRef.current?.scrollTop);

      if (data[0]?.type === "ALL_IN_SPACE") {
        setSelectedUsers(data);
        onSave(data);
        onClose();
      }

      if (isArray(data)) {
        const tempUsers = data.filter((i) => !selectedUsers.some((su) => su.id === i.id));
        setSelectedUsers([...selectedUsers, ...tempUsers]);
      } else {
        const addedUser = selectedUsers.find((item) => item.id === data.id);

        if (addedUser) {
          deletableUserIds.delete(addedUser.id);
          setSelectedUsers(selectedUsers.filter((item) => item.id !== addedUser.id));
        } else {
          deletableUserIds.add(data.id);
          const raw = [...selectedUsers];
          const orderOfUsers = isGroupAll && !meet?.id ? [raw[0], raw[1], data, ...raw.slice(2)] : [raw[0], data, ...raw.slice(1)];
          setSelectedUsers(raw.length ? orderOfUsers : [data]);
        }
      }
    },
    [selectedUsers],
  );

  const handleSave = () => {
    onSave(selectedUsers);
    handleClose();
  };
  const handleClose = () => {
    if (deletedExternalUsersIds.length) {
      dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: meet.id }]));
    }
    onClose();
  };

  useEffect(() => {
    if (listInnerRef.current && !search) {
      listInnerRef.current.scrollTop = scrollPosition;
    }
  }, [listData]);

  useEffect(() => {
    const handleCloseModal = () => {
      if (isOpenContextMenu) {
        setIsOpenContextMenu(false);
      }
    };
    document.addEventListener("click", handleCloseModal);
    return () => document.removeEventListener("click", handleCloseModal);
  }, [isOpenContextMenu]);

  const closeUserInfo = () => {
    setSelectUser(undefined);
  };

  const handleScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      setScrollPosition(scrollTop);

      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 10) {
        return await loadMoreData();
      }
    }
  };

  useEffect(() => {
    setCurrentSublistType("Delegation");

    if (search) {
      setIsWriting(true);
    }

    const query = search ? { query: search } : {};
    const additionalParams = search ? {} : { showFromDelegationChain: true, showFromDepartment: false, showOthers: false };
    const params: IGetUserParams = {
      id: meet.id,
      params: {
        ...query,
        current: 0,
        next,
        startTime: meet?.startTime ?? null,
        endTime: meet?.endTime ?? null,
        userId: "",
        ...additionalParams,
      },
    };

    if (!search) setGetUsersParams(params);

    eventHelperUtils.debounce(
      async () => {
        if (!searchedExternalUsersResponse?.length) {
          setGetUsersParams(params);
          setCurrent(next);
        }
        setIsWriting(false);
      },
      search ? 1000 : 0,
    );
  }, [search, searchedExternalUsersResponse]);

  useEffect(() => {
    setIsSaveBtnVisible(isArraysOfObjectsDiffer(initialParticipants, selectedUsers));
  }, [selectedUsers]);

  const isAdded = useCallback(
    (item: IStateUser | IExternalUser) => selectedUsers.some((i) => (i as IExternalUser).userId === item.id || i.id === item.id),
    [selectedUsers],
  );

  const removeExternalParticipant = (externalUserId: string) => {
    deleteExternalParticipant({ externalUserId, meetId: meet.id, isMeet, disableUpdate: true }).then(() => {
      setSearchedExternalUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setSelectedUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setDeletedExternalUsersIds((prev) => [...prev, externalUserId]);
    });
  };

  const handleRightClick = async (e: MouseEvent<HTMLButtonElement>, item: IStateUser | IExternalUser) => {
    e.preventDefault();
    setExternalUserIdToDelete(item.id);
    setIsOpenContextMenu(true);
    setPos({ top: e.clientY, left: e.clientX });
  };

  return (
    <ModalUI scrollEnable={false} isVisible={true} onClose={handleClose}>
      <HeaderModal title="participants" isCancel={false} onClose={handleClose} onSave={handleSave} isEdit={isSaveBtnVisible} />
      <MainContainer>
        <SearchInput
          maxLength={150}
          isDrop
          value={search}
          styleWrapper={filterContainer}
          setValue={setSearch}
          onDrop={handleResetSearch}
          placeholder="enterNameOrEmail"
          autoRenderDrop
        />

        <RenderWithCondition condition={isInviteExternalParticipantBtnVisible}>
          <AddNonExistingParticipantButton
            email={search}
            setIsInviteExternalParticipantModalVisible={setIsInviteExternalParticipantModalVisible}
          />
        </RenderWithCondition>

        <RenderWithCondition condition={!isInviteExternalParticipantBtnVisible}>
          <>
            {!search && isSelectFromMeeting && !isGroupAll && (
              <SelectFromPastParticipantButton onClick={() => setIsListPastMeetsVisible(true)}>
                <AddParticipantWrapper>
                  <div style={{ width: 42, height: 42 }}>
                    <SvgAddPeople width={42} height={42} />
                  </div>
                  <TextFont size={16} weight="700" type="bold">
                    <FormattedMessage id="selectParticipantsFromMeetings" />
                  </TextFont>
                </AddParticipantWrapper>
                <ArrowSvg type="right" />
              </SelectFromPastParticipantButton>
            )}

            {!search && isSelectGroup && !isGroupAll && (
              <SelectFromPastParticipantButton onClick={() => setIsGroupVisible(true)}>
                <AddParticipantWrapper>
                  <SvgAddPeople fill={Colors.LIGHT.blue} width={42} height={42} />
                  <TextFont size={16} weight="700" type="bold">
                    <FormattedMessage id="selectGroup" />
                  </TextFont>
                </AddParticipantWrapper>
                <ArrowSvg type="right" />
              </SelectFromPastParticipantButton>
            )}

            <LoaderRenderWithCondition condition={isLoading} loaderComponent={<ItemListContentLoader width={"100%"} height={"100%"} />}>
              <div
                ref={listInnerRef}
                style={{
                  height: `calc(91% - ${isSelectGroup && !search ? "12%" : "0%"} - ${isSelectFromMeeting && !search ? "12%" : "0%"})`,
                  overflowY: "auto",
                }}
                onScroll={handleScroll}
              >
                <RenderWithCondition condition={!listData.length && !isFetching && !isLoading && !isWriting}>
                  <NotFoundText>Не найдено</NotFoundText>
                </RenderWithCondition>
                {listData.map((item) => (
                  <>
                    <Item
                      isDeletable={deletableUserIds?.has(item.id)}
                      isBusy={isShowStatusBorder}
                      key={item.id}
                      isAdded={isAdded(item)}
                      item={item}
                      setSelectUser={setSelectUser}
                      onPress={handleUserPress}
                      onRightClick={handleRightClick}
                      isUserHasEditPermissions={isUserHasEditPermissions}
                      isUserHasInvitePermissions={isUserHasInvitePermissions}
                      meetOwnerId={(meet.participants && meet?.participants[0]?.userId) ?? ""}
                    />
                    {(item as IExternalUser).isExternalUser && !(item as IExternalUser).deleted && (
                      <RemoveParticipantsButton
                        externalUserId={item.id}
                        isVisible={isOpenContextMenu && item.id === externalUserIdToDelete}
                        position={{ left: pos.left, top: pos.top }}
                        onClick={() => {
                          setExternalUserIdToDelete(item.id);
                          setIsConfirmModalOpen(true);
                        }}
                      />
                    )}
                  </>
                ))}

                <RenderWithCondition condition={isFetching || isLoading || isWriting}>
                  <Loader posY="95.5%" />
                </RenderWithCondition>
              </div>
            </LoaderRenderWithCondition>
          </>
        </RenderWithCondition>

        <ModalRenderWithCondition condition={Boolean(selectUser)}>
          <ModalUI isVisible={Boolean(selectUser)} onClose={closeUserInfo}>
            <UserInformation user={selectUser} onClose={closeUserInfo} />
          </ModalUI>
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isInviteExternalParticipantModalVisible}>
          <InviteExternalParticipantBottomSheet
            participantEmail={search}
            isVisible={isInviteExternalParticipantModalVisible}
            setIsVisible={setIsInviteExternalParticipantModalVisible}
            setSearch={setSearch}
            closeParticipant={handleClose}
            handleAddUser={handleUserPress}
          />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isListPastMeetsVisible}>
          <MeetsPastList onClose={() => setIsListPastMeetsVisible(false)} onPress={handleUserPress} />
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isGroupVisible}>
          <ModalUI isVisible={isGroupVisible} onClose={() => setIsGroupVisible(false)}>
            <Groups
              onClose={() => setIsGroupVisible(false)}
              forParticipants={true}
              onSave={handleUserPress}
              isGroupAll={isGroupAll}
              isExistMeetID={!!meet?.id}
            />
          </ModalUI>
        </ModalRenderWithCondition>

        <ModalRenderWithCondition condition={isConfirmModalOpen}>
          <NotificationConfirm
            isOpen={true}
            phraseId="externalUserWillDelete"
            phraseOkId="delete"
            phraseCancelId="cancel"
            onOk={() => {
              removeExternalParticipant(externalUserIdToDelete);
              setIsConfirmModalOpen(false);
            }}
            onCancel={() => {
              setIsConfirmModalOpen(false);
            }}
          />
        </ModalRenderWithCondition>
      </MainContainer>
    </ModalUI>
  );
};

const NotFoundText = styled.p`
  text-align: center;
`;

const MainContainer = styled.div`
  padding: 0 12px;
  margin-top: 10px;
  height: 90%;
`;

const SelectFromPastParticipantButton = styled.button`
  display: flex;
  width: 100%;
  padding: 8px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
`;

const AddParticipantWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const filterContainer = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
};
